import React, { useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";

export default () => {
  const [error, setError] = useState(null);
  const [domain, setDomain] = useState("");
  const [isLoading, setLoading] = useState(false);

  const { pathname } = window.location;

  const urlParams = new URLSearchParams(window.location.search);
  const partner = urlParams.get("partner");

  if (partner) {
    Cookies.set("trtle-partner", partner);
  }

  const isFr = pathname.includes("/fr");
  const isEarlyBird = false;
  // pathname.includes("/100")
  // ? 100
  // : pathname.includes("/50")
  // ? 50
  // : null;

  const handleSubmit = async () => {
    try {
      setError(null);
      setLoading(true);
      const regex = RegExp(/^[a-z0-9-_]+$/i);
      console.log("submit", domain, regex.test(domain));
      if (!regex.test(domain)) {
        setError(
          isFr
            ? "Le domaine de votre boutique ne peut contenir que des caractères alphanumeric"
            : "Your store domain can only contain alphanumeric caracters"
        );
        return;
      }
      if (isEarlyBird) {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/early`, {
          domain: `${domain}.myshopify.com`,
          days: isEarlyBird,
        });
      }
      setLoading(false);
      window.location.href = `https://stagingapp.trtleapp.com/auth/inline?shop=${domain}.myshopify.com`;
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const title = `Trtle Upsell - ${
    isFr
      ? `${
          isEarlyBird
            ? `${isEarlyBird} jours offerts`
            : "Installez l'application maintenant"
        }`
      : `${isEarlyBird ? `${isEarlyBird}-day free` : "Install now"}`
  }`;
  const desc = isFr
    ? "Déjà adopté par 127 boutiques. Bénéficiez-en maintenant."
    : "Already adopted by 127 stores. Get it now.";
  const img = isEarlyBird
    ? `/assets/trtle-free${isEarlyBird}days${isFr ? "-fr" : ""}.png`
    : "/assets/shareable.png";
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta property="og:site_name" content={title} />
        <meta property="og:description" name="og:description" content={desc} />
        <meta property="og:image:width" content="1600" />
        <meta property="og:image:height" content="900" />
        <meta property="og:image" content={img} />
        <meta property="og:image:secure_url" content={img} />
        <meta name="twitter:image" content={img} />
        <link rel="image_src" href={img} />
      </Helmet>
      <div className="flex flex-col items-center justify-center px-4 py-8 mx-2 mt-6 mb-2 text-gray-900 bg-white rounded-lg shadow-lg md:py-12 md:px-20 md:mb-4 md:mx-4 md:rounded-xl">
        <div className="text-3xl font-extrabold leading-9 text-center">
          {isFr
            ? isEarlyBird
              ? `Bénéficiez de ${isEarlyBird} jours offerts`
              : "Entrez le nom de votre boutique shopify"
            : isEarlyBird
            ? `Get ${isEarlyBird} days free`
            : "Enter your shopify store name"}
        </div>
        {/* <div className="text-sm text-gray-500">
          Déjà adopté par 127 boutiques. Arrêtez l'essai n'importe quand durant
          les 100 premiers jours.
        </div> */}
        <div className="flex flex-wrap w-full mt-6 rounded-md shadow-sm">
          <div className="relative flex-grow w-full md:w-auto focus-within:z-10">
            <div className="absolute inset-y-0 left-0 flex items-center pl-6 pointer-events-none">
              <span className="text-gray-500">https://</span>
            </div>
            <input
              className="block w-full py-3 pl-20 text-right transition duration-150 ease-in-out form-input md:py-4 md:rounded-none md:rounded-l-md pr-36"
              placeholder={isFr ? "votre-shop" : "your-shop"}
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-6 pointer-events-none">
              <span className="text-gray-500" id="price-currency">
                .myshopify.com
              </span>
            </div>
          </div>
          <button
            className={`${
              isLoading ? "spinner " : ""
            }mt-4 md:mt-0 w-full md:w-auto -ml-px relative inline-flex justify-center items-center px-4 py-3 font-medium rounded-md md:rounded-none md:rounded-r-md text-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150`}
            style={{
              backgroundColor: "#5E6EB7",
            }}
            onClick={handleSubmit}
            disabled={!!!domain || isLoading}
          >
            <span className="ml-2">
              {isFr ? "Installer avec" : "Install with"} Shopify
            </span>
          </button>
        </div>
        {error && <div className="mt-1 text-xs text-red-400">{error}</div>}
        <div className="mt-4 md:mt-6">
          <img
            className="h-6 md:h-8"
            alt="shopify logo"
            src="/assets/shopify.png"
          />
        </div>
      </div>
    </>
  );
};
